import React from 'react'
import Lottie from 'react-lottie'
import NotFoundAnimation from '../../assets/animations/404_animation.json'
const PageNotFoundPage = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: NotFoundAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            frameRate: 30
        },
        speed: 0.2
    };
    return (
        <>
            <Lottie
                options={defaultOptions}
                height={600}
                width={950}
            />
        </>
    )
}

export default PageNotFoundPage