import React from "react";
import style from "../assets/styles/main.module.css";
const ContributionPage = () => {
  return (
    <>
      <div className={style.contribution_container} id="contribution">
        <div className={style.section_heading}>
          <h1>contribution</h1>
        </div>

        <div className={style.contribution_wrapper}>
          <div className={style.contribution_left_content}>
            <div className={style.contribution_left_img_wrapper}>
              <div
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-once
                className={`${style.image_card} my-8 bg-transperent rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}
              >
                <div className={style.contribution_img}>
                  <img
                    className="rounded-t-lg"
                    src="/images/img6.png"
                    alt=""
                  />
                </div>
                <div className="mt-2" data-aos="fade-up" data-aos-once data-aos-duration="1500">
                  {/* <h5 className="text-center mb-2 text-2 font-medium tracking-tight text-white dark:text-white">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Asperiores consequuntur sunt repudiandae, libero rem eos?
                  </h5> */}
                </div>
              </div>
              <div
                className={`${style.image_card}  my-8 bg-transperent rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}
              >
                <div
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-once
                  className={style.contribution_img}
                >
                  <img
                    className="rounded-t-lg"
                    src="/images/img7.png"
                    alt=""
                  />
                </div>
                <div className="mt-2" data-aos="fade-up" data-aos-duration="1500" data-aos-once>
                  {/* <h5 className="text-center mb-2 text-2 font-medium tracking-tight text-white dark:text-white">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Asperiores consequuntur sunt repudiandae, libero rem eos?
                  </h5> */}
                </div>
              </div>
            </div>
            <div className={style.contribution_right_img_wrapper}>
              <div
                data-aos="fade-right"
                data-aos-duration="2000"
                data-aos-once
                className={`${style.image_card}  my-8 bg-transperent rounded-lg shadow `}
              >
                <div className={style.contribution_img}>
                  <img
                    className="rounded-t-lg"
                    src="/images/img8.png"
                    alt=""
                  />
                </div>
                <div className="mt-2" data-aos="fade-up" data-aos-duration="2300" data-aos-once>
                  {/* <h5 className="text-center mb-2 text-2 font-medium tracking-tight text-white dark:text-white">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Asperiores consequuntur sunt repudiandae, libero rem eos?
                  </h5> */}
                </div>
              </div>
            </div>
          </div>
          <div className={style.contribution_right_content}>
            <div data-aos="fade-up" data-aos-duration="1500" data-aos-once className={style.contribution_right_content_wrapper}>
              <h2>Contribution Magazine</h2>
              <p>
                Central to our approach is the cultivation of collaborative partnerships with leaders across diverse industries.
                Through strategic alliances with corporations, government agencies, non-profit organizations, and academic institutions, we create a network of changemakers committed to driving positive social impact.
              </p>
            </div>
            <div id="tilt-image" className={style.contribution_right_img}>
              <img src="/images/contributionVRImg.png" alt="VR" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContributionPage;
