import React from 'react'
import { AiOutlineStock } from 'react-icons/ai'
import { GiArtificialIntelligence, GiRaceCar } from 'react-icons/gi'
import { LuSchool } from 'react-icons/lu'
import { PiVirtualRealityLight } from 'react-icons/pi'
import { Tb360View } from 'react-icons/tb'
import { Link } from 'react-router-dom'

const ProductsContent = () => {
    return (
        <>
            <div className="w-full flex justify-center">
                <div className='w-full py-6'>
                    <div class={`w-[100%] h-auto justify-center  flex flex-col-reverse rounded-lg shadow`}>
                        <div className='w-full h-full grid grid-cols-1 grid-rows-[4fr] gap-4  lg:grid-cols-3  sm:grid-cols-2 '>
                            <div data-aos="zoom-in" data-aos-delay="200" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <PiVirtualRealityLight color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-lg text-start mb-0.5'>KalpVerse VR Lab</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm '>Welcome to KalpVerse VR Lab for Schools, where education meets virtual reality for boundless learning adventures! We're committed to revolutionizing learning with immersive experiences... <span><Link to="/service/products" className='underline text-blue-500' >Read more</Link></span> </p>
                                </div>
                            </div>
                            <div data-aos="zoom-in" data-aos-delay="200" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <GiRaceCar color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-lg text-start mb-0.5'>Dirtrage</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm '>
                                        Welcome to DirtRage Games, where adrenaline meets the digital world! DirtRage Games is your destination for heart-pounding, high-octane gaming experiences. Specializing in off-road racing and... <span><Link to="/service/products" className='underline text-blue-500' >Read more</Link></span>
                                    </p>
                                </div>
                            </div>
                            <div data-aos="zoom-in" data-aos-delay="200" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <Tb360View color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-lg text-start mb-0.5'>360° Walkthrough </h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm '>
                                        we specialize in creating immersive 360° Walkthrough virtual tours! Step into a world of breathtaking views and interactive experiences as you explore our virtual environments. Whether you're showcasi... <span><Link to="/service/products" className='underline text-blue-500' >Read more</Link></span> </p>
                                </div>
                            </div>
                            <div data-aos="zoom-in" data-aos-delay="400" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <LuSchool color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-lg text-start mb-0.5'>School Management Software </h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm '>Introducing  School Management Software, your trusted partner in school management solutions! Your all-in-one solution for streamlining administrative tasks, enhancing communication... <span><Link to="/service/products" className='underline text-blue-500' >Read more</Link></span> </p>
                                </div>
                            </div>
                            <div data-aos="zoom-in" data-aos-delay="400" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <GiArtificialIntelligence color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-lg text-start mb-0.5'>Point of Sale System</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm '>
                                        Streamline your retail operations with our comprehensive solution, managing sales, inventory, and customer transactions seamlessly. Say goodbye to manual processes and hello to efficiency with... <span><Link to="/service/products" className='underline text-blue-500' >Read more</Link></span> </p>
                                </div>
                            </div>
                            <div data-aos="zoom-in" data-aos-delay="400" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <AiOutlineStock color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-lg text-start mb-0.5'>Stock Management System</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm '>Our platform offers a comprehensive solution for efficient inventory management. Easily track stock levels, monitor inventory movement, and streamline order processing. With Vradicals Stock... <span><Link to="/service/products" className='underline text-blue-500' >Read more</Link></span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductsContent