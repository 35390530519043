import React, { useState } from 'react'
import style from "../assets/styles/main.module.css";
import ServiceContent from './components/ServiceContent';
import TechnologyContent from './components/TechnologyContent';
import ProductsContent from './components/ProductsContent';

const ServicePage = () => {
    const [service, setService] = useState(true);
    const [technology, setTechnology] = useState(false)
    const [products, setProducts] = useState(false)
    const handleClick = (data) => {
        console.log(data)
        if (data === "Products") {
            setProducts(true)
            setTechnology(false)
            setService(false)
        }
        else if (data === "Technology") {
            setTechnology(true)
            setProducts(false)
            setService(false)
        }
        else {
            setService(true)
            setProducts(false)
            setTechnology(false)
        }
    }

    return (
        <>
            <div className='h-auto px-4' id='service'>
                <div className='px-6' >
                    <div className='mt-6' >
                        <div className='h-auto flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-8'>
                            <button onClick={() => handleClick("Service")} className={`${style.bt} text-2xl font-bold  ${service ? 'text-[#fe00e3] underline' : 'text-white'}`}>Service</button>
                            <button onClick={() => handleClick("Technology")} className={`${style.bt} text-2xl font-bold ${technology ? 'text-[#fe00e3] underline' : 'text-white'}`}>Technology</button>
                            <button onClick={() => handleClick("Products")} className={`${style.bt} text-2xl font-bold ${products ? 'text-[#fe00e3] underline' : 'text-white'}`}>Products</button>
                        </div>
                    </div>
                    {service && <ServiceContent />}
                    {technology && <TechnologyContent />}
                    {products && <ProductsContent />}
                    <div className={style.BG_Shraed} />
                </div>
            </div>
        </>
    )
}

export default ServicePage