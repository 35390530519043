import { useEffect } from "react";
import { BrowserRouter, Route, Routes,  } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast";
import AboutPage from "./About/AboutPage";
import BlogPage from "./Blog/BlogPage";
import ContributionPage from "./Contribution/ContributionPage";
import FooterPage from "./Footer/FooterPage";
import HomePage from "./Home/HomePage";
import MediaEventPage from "./Media&Event/MediaEventPage";
import TestimonialPage from "./Testinominal/TestimonialPage";
import ThoughtsPage from "./Thoughts/ThoughtsPage";
import Navbar from "./components/sheared/Navbar";
import "./index.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import VanillaTilt from "vanilla-tilt";
import LearnMoreAbout from "./About/LearnMoreAbout.jsx";
import MainPages from "./MainPages";
import LearnMoreService from './servicePage/LearnMoreServicePage.jsx'
import LeanMoreBlog from "./Blog/LeanMoreBlog";
import ContactPage from "./contact/ContactPage";
import TearmCondition from "./Footer/TearmCondition";
import FAQ from "./Footer/FAQ";
import PrivacyPolicy from "./Footer/PrivacyPolicy.jsx";
import SuccessPage from "./components/sheared/SuccessPage.jsx";
import PageNotFoundPage from "./components/sheared/PageNotFoundPage.jsx";
import LeanMoreTechPage from "./servicePage/LeanMoreTechPage.jsx";
import LearnMoreProducts from "./servicePage/LearnMoreProducts.jsx";
import MoreMedia from "./Media&Event/MoreMedia.jsx";

function App() {
  useEffect(() => {
    VanillaTilt.init(document.getElementById("tilt-image"), {
      max: 25,
      speed: 400,
    });
  }, []);
  return (
    <>
      <BrowserRouter >
        <Routes>
          <Route path="/" element={<MainPages />} />
          <Route path="/about" element={<LearnMoreAbout />} />
          <Route path="/service" element={<LearnMoreService />} />
          <Route path="/service/technology" element={<LeanMoreTechPage />} />
          <Route path="/service/products" element={<LearnMoreProducts />} />
          <Route path="/blog" element={<LeanMoreBlog />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/term-condition" element={<TearmCondition />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/media-and-events" element={<MoreMedia />} />
          <Route path="*" element={<PageNotFoundPage />} /> 
          
        </Routes>
        <Toaster position="top-center" reverseOrder={false} />
      </BrowserRouter>
      
    </>
  );
}

export default App;
