import React, { useEffect } from 'react'
import style from '../assets/styles/section.module.css'
import FooterPage from '../Footer/FooterPage.jsx'
import homeVideo from '../assets/videos/VRadicals_Video.mp4'
import SectionNavbar from '../components/sheared/SectionNavbar'
const LeanMoreBlog = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SectionNavbar />
            <section className={style.about_main_container}>
                <div className={style.about_wrapper}>
                    <video
                        src={homeVideo}
                        autoPlay
                        muted
                        loop
                        className={`${style.home_video} object-cover`}
                    ></video>
                    <img src="/images/headingBG.png" alt="headingImg" />
                    <div className={style.about_img_effect}></div>
                    <h1 className={style.section_heading}>Blog</h1>
                </div>
                <div className={style.blog_content_container}>
                    <div className={style.blog_content_wrapper}>
                        <img src="/images/section_blog_img.png" alt="" />
                        <div className={style.blog_content}>
                            <h1>5 VR Applications for Elders</h1>
                            <p className='text-gray-400'>In recent years, Virtual Reality (VR) has emerged as a powerful tool for enhancing the lives of individuals across various age groups. While it's commonly associated with gaming and entertainment, VR's potential reaches far beyond. Specifically designed applications cater to seniors, offering them unique experiences that promote relaxation, understanding, and connection. Here are five noteworthy VR applications tailored to meet the needs of our elderly population.</p>
                        </div>
                        <div className={style.blog_mid_content}>
                            <div>
                            <div className={style.blog_mid_text} style={{marginBottom:'1rem'}}>
                                <h1>Zen Zone: Finding Serenity in Virtual Reality</h1>
                                <p className='text-gray-400'>For seniors seeking solace and tranquility, Zen Zone provides a haven of relaxation. This application transports users to serene environments, complete with meditative experiences and Zen gardens. It's a digital sanctuary where seniors can unwind, alleviate stress, and find inner peace through immersive VR experiences.</p>
                            </div>
                            <div className={style.blog_mid_text}>
                                <h1>Appility: Fostering Empathy and Connection</h1>
                                <p className='text-gray-400'>Understanding the challenges faced by individuals with disabilities is crucial for empathy and compassion. Appility allows seniors to step into the shoes of those living with disabilities, fostering a deeper understanding and connection with their fellow humans. It's an eye-opening experience that promotes empathy and inclusivity among seniors and their families.</p>
                            </div>
                            </div>
                            
                            <img src="/images/section_about_img1.png" alt="" />
                        </div>
                        <div className={style.blog_content}>
                            {/* <h1>Appility: Fostering Empathy and Connection</h1> */}
                            <p className='text-gray-400'>Virtual Reality has evolved into a versatile tool, offering tailored experiences to enhance the lives of seniors. Through applications like Zen Zone, Appility, Alcove, Wander, and National Geographic VR, seniors can find relaxation, empathy, connection, exploration, and adventure, all within the immersive realm of VR. These applications represent just a glimpse into the boundless potential of VR technology in enriching the lives of our elderly population. As VR continues to advance, so too will the opportunities for seniors to discover new horizons and create meaningful memories.</p>
                        </div>
                    </div>
                </div>
                <FooterPage />
            </section>
        </>
    )
}

export default LeanMoreBlog