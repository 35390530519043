import React from 'react'
import { FaJava } from 'react-icons/fa'
import { GiArtificialIntelligence } from 'react-icons/gi'
import { SiUnrealengine } from 'react-icons/si'
import { TbBrandFlutter, TbBrandPython, TbBrandReactNative } from 'react-icons/tb'
import { Link } from 'react-router-dom'

const TechnologyContent = () => {
    return (
        <>
            <div className="w-full flex justify-center">
                <div className='w-full py-6'>
                    <div class={`w-[100%] h-auto justify-center  flex flex-col-reverse rounded-lg shadow`}>
                        <div className='w-full h-full grid grid-cols-1 grid-rows-[4fr] gap-4  lg:grid-cols-3  sm:grid-cols-2 '>
                            <div data-aos="zoom-in" data-aos-delay="200" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <SiUnrealengine color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available] '>
                                    <h1 className='text-white font-bold text-md text-start mb-0.5 sm:text-lg'>Unreal Engine</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm sm:text-md'>
                                        Harness the power of Unreal Engine at Vradicals for immersive gaming and interactive experiences. We specialize in delivering high-performance solutions that captivate audiences and elevate... <span><Link to="/service/technology" className='underline text-blue-500' >Read more</Link></span>  </p>
                                </div>
                            </div>
                            <div data-aos="zoom-in" data-aos-delay="200" data-aos-once className='border rounded flex justify-between flex-col items-center gap-4 p-2 h-fit sm:h-64 sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <TbBrandPython color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-md text-start mb-0.5'>Python</h1>
                                    <p className='inline items-start y text-start text-gray-400 text-sm'>
                                        At Python, we're problem solvers, innovators, and collaborators, leveraging cutting-edge technologies to create bespoke solutions for your unique challenges. Whether AI-driven insights or secure... <span><Link to="/service/technology" className='underline text-blue-500' >Read more</Link></span> </p>
                                </div>
                            </div>

                            <div data-aos="zoom-in" data-aos-delay="200" data-aos-once className='border rounded flex justify-between flex-col items-center gap-4 p-2 h-fit sm:h-64 sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <TbBrandReactNative color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-lg text-start mb-0.5'>React Native</h1>
                                    <p className='inline items-start y text-start text-gray-400 text-sm'>
                                        Our React Native experts are committed to realizing your mobile app vision. With extensive experience and innovative drive, we deliver tailored solutions, ensuring your app surpasses... <span><Link to="/service/technology" className='underline text-blue-500' >Read more</Link></span> </p>
                                </div>
                            </div>

                            <div data-aos="zoom-in" data-aos-delay="400" data-aos-once className='border rounded flex justify-between flex-col items-center gap-4 p-2 h-fit sm:h-64 sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <TbBrandFlutter color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-md text-start mb-0.5'>Flutter</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm'>
                                        Harnessing Flutter's versatility, our adept team crafts stunning, high-performance apps for iOS and Android. Leveraging its rapid development and expressive UI, we ensure delightf... <span><Link to="/service/technology" className='underline text-blue-500' >Read more</Link></span> </p>
                                </div>
                            </div>
                            <div data-aos="zoom-in" data-aos-delay="400" data-aos-once className='border rounded flex justify-between flex-col items-center gap-4 p-2 h-fit sm:h-64 sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <FaJava color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-md text-start mb-0.5'>JAVA</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm'>
                                        With our profound Java programming expertise, we provide scalable solutions spanning enterprise, web, and mobile applications. Leveraging Java's versatility, we tailor solutions to meet your uni... <span><Link to="/service/technology" className='underline text-blue-500' >Read more</Link></span> </p>
                                </div>
                            </div>
                            <div data-aos="zoom-in" data-aos-delay="400" data-aos-once className='border rounded flex justify-between flex-col items-center gap-4 p-2 h-fit  sm:h-64 sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <GiArtificialIntelligence color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-md text-start mb-4'>Machine Learning</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm'>Harnessing advanced algorithms and data-driven insights, our machine learning solutions drive innovation and efficiency. Partner with us to unlock the full potential of predictive analytics... <span><Link to="/service/technology" className='underline text-blue-500' >Read more</Link></span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TechnologyContent