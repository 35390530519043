import React from 'react'
import style from "../assets/styles/main.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import './style.css'

const PartnerCrousel = () => {
    const StyledCarousel = styled(Carousel)`
    width: 100%;
    .carousel .slide {
       min-width: 15%
      }
  
    @media (min-width: 768px) {
      width: 80%;
    }
  
    @media (max-width: 1024px) {
      width: 100%;
    }
    
  `;
    return (
        <>
            <div className={`${style.section_heading} static`} id="partner">
                <h1>Partners</h1>
            </div>
            <section class="slide-option">
                <div id="infinite" class="highway-slider">
                    <div class="highway-barrier" >
                        <ul class="highway-lane flex gap-3">
                            <li class="highway-car"><span class="fab "><img src="/images/unity.png" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/unreal.png" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/aditya.png" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/medi-caps.png" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/techy.png" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/Inspired.png" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/IPS.png" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/dainik2.png" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/patrika1.jpg" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/news18.png" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/MANAV.png" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/meta.png" alt="" srcset="" /></span></li>
                            <li class="highway-car"><span class="fab "><img src="/images/YMCA.png" alt="" srcset="" /></span></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PartnerCrousel