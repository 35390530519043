import React from "react";
import style from "../assets/styles/main.module.css";
import { BsArrowRightCircle } from "react-icons/bs"
import blogVideo from '../assets/videos/blog_video.mp4'
import { Link } from 'react-router-dom'
const BlogPage = () => {
  const numberOfTimes = 3; // Number of times to repeat the code
  const items = [];

  for (let i = 0; i < numberOfTimes; i++) {
    items.push(
      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        key={i}
        class="flex flex-col items-center bg-transperent  rounded-lg shadow md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 "
      >
        <img
          class="object-cover w-full  rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none "
          src="images/contributionVRImg.png"
          alt=""
        />
        <div class="flex flex-col justify-between p-4 leading-normal">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
            How Web 3.0 will change our ways of using social media?
          </h5>
          <p class="mb-3 font-normal text-gray-400 ">
            MR integrates physical and digital seamlessly. In these immersive environments, understanding spatial interaction is key.
          </p>
          <button
            className={`${style.blog_readBtn} w-[10rem] flex items-center gap-4 bg-transparent  text-white font-bold border border-white rounded-full `}
          >
            Request a Demo
            <BsArrowRightCircle size={25} />
          </button>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={style.blog_container} id="blog">
        <div className={style.section_heading}>
          <h1>Blog</h1>
        </div>
        <div className={style.blog_content_wrapper}>
          <div className={style.blog_content}>
            <div
              data-aos="zoom-out-right"
              data-aos-duration="1000"
              data-aos-once
              className={style.blog_left_wrapper}
            >
              <div className={style.blog_left_img}>
                <video
                  src={blogVideo}
                  autoPlay
                  muted
                  loop
                  className="object-cover"
                  style={{
                    height: '-webkit-fill-available',
                    width: '-webkit-fill-available',
                  }}
                ></video>
                <div className={style.blog_left_img_text}>
                  <p>
                    Discover the Latest in Upcoming Technology
                  </p>
                </div>
              </div>
            </div>

            <div className="w-[60%] ">
              <div
                data-aos="fade-left"
                data-aos-duration="1300"
                data-aos-once
                // key={i}
                class="flex flex-col items-center bg-transperent mb-4  rounded-lg shadow md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 "
              >
                <img
                  class="object-cover w-full  rounded-t-lg  md:h-auto md:w-44 md:rounded-none "
                  src="images/blog1.png"
                  alt=""
                />
                <div class={`${style.blog_card_content} flex flex-col justify-between pl-4 leading-normal`}>
                  <h5 class="mb-2 text-xl font-bold tracking-tight text-white">
                    How Web 3.0 will change our ways of using social media?
                  </h5>
                  <p class="mb-3 text-sm font-normal text-gray-400 ">
                    Web3 introduces a paradigm shift. It champions decentralization, giving users unprecedented control over their data.
                  </p>
                  <Link to="/blog">
                    <button
                      className={`${style.blog_readBtn} flex  items-center gap-4 bg-transparent  text-white font-bold border border-white rounded-full `}
                    >
                      Learn More
                      <BsArrowRightCircle size={18} />
                    </button>
                  </Link>

                </div>
              </div>

              <div
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-once
                // key={i}
                class="flex flex-col mb-4 items-center bg-transperent  rounded-lg shadow md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 "
              >
                <img
                  class="object-cover w-full  rounded-t-lg  md:h-auto md:w-44 md:rounded-none "
                  src="images/blog2.png"
                  alt=""
                />
                <div class={`${style.blog_card_content} flex flex-col justify-between pl-4 leading-normal`}>
                  <h5 class="mb-2 text-xl font-bold tracking-tight text-white">
                    Here are 3 Major Principles of MR Design.
                  </h5>
                  <p class="mb-3 text-sm font-normal text-gray-400 ">
                    MR integrates physical and digital seamlessly. In these immersive environments, understanding spatial interaction is key.
                  </p>
                  <Link to="/blog">
                    <button
                      className={`${style.blog_readBtn} flex  items-center gap-4 bg-transparent  text-white font-bold border border-white rounded-full `}
                    >
                      Learn More
                      <BsArrowRightCircle size={18} />
                    </button>
                  </Link>
                </div>
              </div>

              <div
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-once
                // key={i}
                class="flex flex-col items-center bg-transperent  rounded-lg shadow md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 "
              >
                <img
                  class="object-cover w-full  rounded-t-lg md:h-auto md:w-44 md:rounded-none "
                  src="images/blog3.png"
                  alt=""
                />
                <div class={`${style.blog_card_content} flex flex-col justify-between pl-4  leading-normal`}>
                  <h5 class="mb-2 text-xl font-bold tracking-tight text-white">
                    5 VR Applications for Elders
                  </h5>
                  <p class="mb-3 text-sm font-normal text-gray-400 ">
                    Here are our top favourite VR experiencing applications for our beloved elders.
                  </p>
                  <Link to="/blog">
                    <button
                      className={`${style.blog_readBtn} flex  items-center gap-4 bg-transparent  text-white font-bold border border-white rounded-full `}
                    >
                      Learn More
                      <BsArrowRightCircle size={18} />
                    </button>
                  </Link>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
