import React from 'react'
import { AiOutlineAndroid } from 'react-icons/ai'
import { BsShop } from 'react-icons/bs'
import { FaReact } from 'react-icons/fa'
import { GiArtificialIntelligence } from 'react-icons/gi'
import { LuGamepad2 } from 'react-icons/lu'
import { PiVirtualRealityLight } from 'react-icons/pi'
import { Link } from 'react-router-dom'

const ServiceContent = () => {
    return (
        <>
            <div className="w-full flex justify-center">
                <div className='w-full py-6'>
                    <div class={`w-[100%] h-auto justify-center  flex flex-col-reverse rounded-lg shadow`}>
                        <div className='w-full h-full grid grid-cols-1 grid-rows-[4fr] gap-4  lg:grid-cols-3  sm:grid-cols-2 '>

                            <div data-aos="zoom-in" data-aos-delay="200" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <PiVirtualRealityLight color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-lg text-start mb-0.5'>VR Development</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm '>At Vradicals we specialize in pushing the boundaries of innovation through cutting-edge Virtual Reality (VR) development services. Step into a world where imagination meets techno... <span><Link to="/service" className='underline text-blue-500' >Read more</Link></span></p>
                                </div>
                            </div>

                            <div data-aos="zoom-in" data-aos-delay="200"  data-aos-duration="600" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <AiOutlineAndroid color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-md text-start mb-0.5'>MOBILE APP DEVELOPMENT</h1>
                                    <p className='inline items-start y text-start text-gray-400 text-sm'>
                                        We specialize in creating captivating mobile apps with engaging user experiences and robust backend functionality. From native to hybrid solutions, we turn your... <span><Link to="/service" className='underline text-blue-500' >Read more</Link></span></p>
                                </div>
                            </div>

                            <div data-aos="zoom-in"  data-aos-delay="200" data-aos-duration="600" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <LuGamepad2 color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-lg text-start mb-0.5'>Game Development</h1>
                                    <p className='inline items-start y text-start text-gray-400 text-sm'>
                                        Game Development is one of our most trusted services, beloved by our clients. Whether you're looking for 2D, 3D, or 2.5D games, we've got you covered. Our team of experienced devel... <span><Link to="/service" className='underline text-blue-500' >Read more</Link></span>  </p>
                                </div>
                            </div>

                            <div data-aos="zoom-in" data-aos-delay="400" data-aos-duration="600" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <FaReact color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-md text-start mb-0.5'>WEB APPLICATIONS</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm'>Elevate your online presence with a custom web application designed just for you! At Vradicals we're passionate about crafting digital solutions that drive growth and enhance user experien...<span><Link to="/service" className='underline text-blue-500' >Read more</Link></span> </p>
                                </div>
                            </div>

                            <div data-aos="zoom-in" data-aos-delay="400" data-aos-once  data-aos-duration="600" className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <GiArtificialIntelligence color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-md text-start mb-0.5'>AI DEVELOPMENT</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm '>
                                        We're at the forefront of AI development. Our team harnesses the latest advancements in artificial intelligence to create innovative solutions tailored to your business needs... <span><Link to="/service" className='underline text-blue-500' >Read more</Link></span>  </p>
                                </div>
                            </div>

                            <div data-aos="zoom-in"  data-aos-delay="400" data-aos-once className='border rounded flex justify-between flex-col  items-center gap-4 p-2 h-fit sm:h-64  sm:flex-row sm:gap-6'>
                                <span className='w-auto'>
                                    <BsShop color='white' size={100} />
                                </span>
                                <div className='w-[-webkit-fill-available]'>
                                    <h1 className='text-white font-bold text-md text-start mb-0.5'>ONLINE MARKETING</h1>
                                    <p className='inline items-start y text-justify text-gray-400 text-sm'> We specialize in helping businesses thrive in the digital world. With our expertise in online marketing, we empower brands to reach their target audience, increase visibility, and drive meaningful... <span><Link to="/service" className='underline text-blue-500'>Read more</Link></span> </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceContent