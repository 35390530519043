import React, { useEffect } from 'react'
import style from '../assets/styles/section.module.css'
import FooterPage from '../Footer/FooterPage'
import homeVideo from '../assets/videos/VRadicals_Video.mp4'
import SectionNavbar from '../components/sheared/SectionNavbar'

const LeanMoreTechPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SectionNavbar />
            <section className={style.about_main_container}>
                <div className={style.about_wrapper}>
                    <video
                        src={homeVideo}
                        autoPlay
                        muted
                        loop
                        className={`${style.home_video} object-cover`}
                    ></video>
                    <img src="/images/headingBG.png" alt="headngImg" />
                    <div className={style.about_img_effect}></div>
                    <h1 className={style.section_heading}>Technology</h1>
                </div>

                <div className={style.card_container}>
                    <div className={style.card_wrapper}>
                        <div className={style.card_inner_wrapper}>
                            <div className={style.service_card}>
                                <img className='rounded' loading='lazy' src="/images/service_icon/tech_imgs/unreal.png" alt="img" />
                            </div>

                        </div>
                        <div className={`${style.card_inner_wrapper} justify-center`}>
                            <div className='mb-2'>
                                <h1 className='text-3xl text-white font-bold'>Unreal</h1>
                            </div>
                            <p>At Vradicals, we're passionate about pushing the boundaries of immersive gaming and interactive experiences through the incredible capabilities of Unreal Engine development. With our dedicated team of experts, we specialize in harnessing the full potential of Unreal Engine to bring your visions to life in ways that surpass your expectations. Whether you're looking to create cutting-edge video games, breathtaking virtual reality experiences, or captivating interactive simulations, we have the expertise and resources to make it happen.

                                Our journey begins with a deep understanding of your goals, ideas, and audience. We collaborate closely with you to conceptualize and design experiences that not only meet but exceed your objectives. Leveraging the advanced tools and features of Unreal Engine, we craft stunning visuals, realistic environments, and captivating gameplay mechanics that immerse players in unforgettable worlds.</p>
                            {/* <div className='flex justify-start gap-4'>
                                <span >
                                    <img loading='lazy' className='w-[5rem] bg-white rounded aspect-squre object-contain' src="/images/service_icon/unity.png" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-[5rem] bg-white rounded aspect-squre object-contain' src="/images/service_icon/unreal.png" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-[5rem] bg-white rounded aspect-squre object-contain' src="/images/service_icon/blender.png" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-[5rem] bg-white rounded aspect-squre object-contain' src="/images/service_icon/oculus.png" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-[5rem] bg-white rounded aspect-squre object-contain' src="/images/service_icon/adobe_aero.png" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-[5rem] bg-white rounded aspect-squre object-contain' src="/images/service_icon/after_effect.png" alt="img" />
                                </span>

                            </div> */}

                        </div>
                    </div>

                    <div className={`${style.card_wrapper} flex-row-reverse `}>
                        <div className={style.card_inner_wrapper}>
                            <div className={style.service_card}>
                                <img className='rounded' loading='lazy' src="/images/service_icon/tech_imgs/react.png" alt="img" />
                            </div>

                        </div>
                        <div className={`${style.card_inner_wrapper} justify-center`}>
                        <div className='mb-2'>
                                <h1 className='text-3xl text-white font-bold'>React</h1>
                            </div>
                            <p>

                                At our React expert team, we're not just developers; we're visionaries dedicated to transforming your web application dreams into reality. With years of collective experience and an unwavering commitment to innovation, our professionals possess a deep understanding of React and its vast ecosystem. We don't just write code; we craft experiences that resonate with your users and drive your business forward.

                                Our journey begins with a thorough understanding of your requirements and objectives. We take the time to listen, collaborate, and brainstorm, ensuring that we're aligned with your vision every step of the way. Whether you're looking to build a sleek and modern user interface or integrate complex backend systems seamlessly, we have the proficiency and knowledge to deliver.

                                With React as our primary tool, we leverage its flexibility, scalability, and performance to create web applications that exceed expectations.</p>
                            {/* <div className='flex justify-start gap-4'>
                                <span >
                                    <img loading='lazy' className='w-15% aspect-squre object-contain bg-white rounded' src="/images/service_icon/Mobile_App/mobileadroid.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-15% aspect-squre object-contain bg-white rounded' src="/images/service_icon/Mobile_App/mobileflutter.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-15% aspect-squre object-contain bg-white rounded' src="/images/service_icon/Mobile_App/mobileios.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-15% aspect-squre object-contain bg-white rounded' src="/images/service_icon/Mobile_App/mobiletitanium.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-15% aspect-squre object-contain bg-white rounded' src="/images/service_icon/Mobile_App/mobilexamarin.webp" alt="img" />
                                </span>

                            </div> */}

                        </div>
                    </div>

                    <div className={`${style.card_wrapper} `}>
                        <div className={style.card_inner_wrapper}>
                            <div className={style.service_card}>
                                <img className='rounded' loading='lazy' src="/images/service_icon/tech_imgs/nextjs.png" alt="img" />
                            </div>

                        </div>
                        <div className={`${style.card_inner_wrapper} justify-start`}>
                        <div className='mb-2'>
                                <h1 className='text-3xl text-white font-bold'>Next.js</h1>
                            </div>
                            <p>

                                With a focus on web application vision with precision and excellence, our team is dedicated to pushing the boundaries of what's possible in digital innovation. We pride ourselves on our extensive experience and relentless drive for staying at the forefront of technology trends. By harnessing the power of React coupled with Next.js, we ensure that every web application we craft is not only dynamic and performant but also scalable and future-proof.

                                In addition to building SEO-friendly pages and implementing server-side rendering for enhanced speed and scalability, we go beyond the basics to provide comprehensive solutions tailored to your specific requirements. Whether it's integrating complex functionalities, optimizing for mobile responsiveness, or ensuring seamless user experiences, we approach every project with a commitment to excellence and attention to detail.</p>
                            {/* <div className='flex justify-start gap-4'>
                                <span >
                                    <img loading='lazy' className='w-[5rem] bg-white rounded aspect-squre object-contain ' src="/images/service_icon/unity.png" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-[5rem] bg-white rounded aspect-squre object-contain ' src="/images/service_icon/unreal.png" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-[5rem] bg-white rounded aspect-squre object-contain ' src="/images/service_icon/google_engine.png" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-[5rem] bg-white rounded aspect-squre object-contain ' src="/images/service_icon/game_maker.png" alt="img" />
                                </span>

                            </div> */}
                        </div>
                    </div>

                    <div className={`${style.card_wrapper} flex-row-reverse `}>
                        <div className={style.card_inner_wrapper}>
                            <div className={style.service_card}>
                                <img className='rounded' loading='lazy' src="/images/service_icon/tech_imgs/flutter.png" alt="img" />
                            </div>

                        </div>
                        <div className={`${style.card_inner_wrapper} justify-start`}>
                        <div className='mb-2'>
                                <h1 className='text-3xl text-white font-bold'>Flutter</h1>
                            </div>
                            <p >At our core, we're a team of dedicated and skilled developers who are passionate about leveraging the latest technologies to create exceptional mobile applications. With Flutter's flexible framework at our fingertips, we're able to craft stunning and high-performance applications that cater to both iOS and Android platforms seamlessly.

                                One of the key advantages of working with Flutter is its hot reload feature, which allows us to iterate rapidly and see changes in real-time. This means that we can refine and fine-tune the user experience quickly, ensuring that your app not only meets but exceeds expectations. Additionally, Flutter's expressive UI toolkit empowers us to create beautiful and intuitive interfaces that captivate users from the moment they launch the app.
                            </p>
                            {/* <div className='flex justify-start gap-4'>
                                <span >
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/webangularjss.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/react.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/webjava.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/webmongodb.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/webnodejs.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/webwp.webp" alt="img" />
                                </span>
                            </div> */}
                        </div>
                    </div>

                    <div className={`${style.card_wrapper} `}>
                        <div className={style.card_inner_wrapper}>
                            <div className={style.service_card}>
                                <img className="rounded" loading='lazy' src="/images/service_icon/tech_imgs/java.png" alt="img" />
                            </div>

                        </div>
                        <div className={`${style.card_inner_wrapper} justify-start`}>
                        <div className='mb-2'>
                                <h1 className='text-3xl text-white font-bold'>Java</h1>
                            </div>
                            <p >
                                With our extensive expertise in Java programming, we offer a comprehensive suite of services aimed at delivering robust and scalable solutions across various applications. Leveraging the versatility and reliability of Java, our skilled team excels in crafting tailored solutions that precisely align with your business requirements. Whether you're in need of enterprise software, web applications, or mobile apps, we have the knowledge and experience to bring your vision to life. Java's flexibility allows us to address a wide range of challenges and complexities, ensuring that our solutions are not only powerful but also adaptable to evolving needs. By partnering with us, you gain access to a team of Java experts dedicated to pushing the boundaries of innovation and excellence. Together, we can harness the full potential of Java to elevate your projects to new heights of success.
                            </p>
                            {/* <div className='flex justify-start gap-4'>
                                <span >
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/webangularjss.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/react.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/webjava.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/webmongodb.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/webnodejs.webp" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-25vw aspect-squre object-contain bg-white rounded' src="/images/service_icon/Web_Development/webwp.webp" alt="img" />
                                </span>
                            </div> */}
                        </div>
                    </div>

                    <div className={`${style.card_wrapper} flex-row-reverse `}>
                        <div className={style.card_inner_wrapper}>
                            <div className={style.service_card}>
                                <img className="rounded" loading='lazy' src="/images/service_icon/tech_imgs/python.png" alt="img" />
                            </div>

                        </div>
                        <div className={`${style.card_inner_wrapper} justify-start`}>
                        <div className='mb-2'>
                                <h1 className='text-3xl text-white font-bold'>Python</h1>
                            </div>
                            <p>
                                At Python, we pride ourselves on being more than just a team of developers—we are problem solvers, innovators, and collaborators. With a collective passion for technology and a deep understanding of the latest industry trends, we leverage our diverse expertise to craft bespoke solutions that address your unique challenges head-on. Whether you're looking to harness the power of AI-driven insights to propel your business forward or seeking to implement secure blockchain applications to revolutionize your operations, our seasoned experts are here to transform your vision into reality. We believe in the power of partnership and strive to work closely with our clients every step of the way, ensuring that our solutions not only meet but exceed their expectations. With Python by your side, you can trust that you'll receive not only cutting-edge technology but also unparalleled support and collaboration.
                            </p>
                            {/* <div className='flex justify-start'>
                                <span >
                                    <img loading='lazy' className='w-15% aspect-squre object-contain ' src="/images/imgy.png" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-15% aspect-squre object-contain ' src="/images/unreal.png" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-15% aspect-squre object-contain ' src="/images/godot.png" alt="img" />
                                </span>
                                <span>
                                    <img loading='lazy' className='w-15% aspect-squre object-contain ' src="/images/gamemaker_studio.png" alt="img" />
                                </span>
                            </div> */}
                        </div>
                    </div>
                </div>

                <FooterPage />
            </section>
        </>
    )
}

export default LeanMoreTechPage