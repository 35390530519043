import React from 'react'
import Lottie from 'react-lottie'
import successAnimation from '../../assets/animations/success_animation.json'

const SuccessPage = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: successAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            frameRate: 30
        },
        speed: 0.2
    };
    return (
        <>
            <div className='flex justify-center items-center h-[100lvh]'>
                <a class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <h5 class="mb-2 text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Thank you for reaching out to us! </h5>
                    <Lottie
                        options={defaultOptions}
                        height={130}
                        width={130}
                    />
                    <p class="font-normal text-gray-700 dark:text-gray-400">
                        Your message has been successfully submitted. We appreciate your interest and will get back to you as soon as possible.
                    </p>
                    <a href="/" className='flex justify-center mt-4 items-center text-center text-blue-600'>
                        <svg class="w-4 h-4 mr-2 text-blue-700 dark:text-white" aria-hidden="true" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5H1m0 0 4 4M1 5l4-4" />
                        </svg> Back to home
                    </a>
                </a>
            </div>
        </>
    )
}

export default SuccessPage