import React from "react";
import style from "../assets/styles/main.module.css";
import { MdDownloadForOffline } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
const TestimonialPage = () => {

  const StyledCarousel = styled(Carousel)`
  width: 100%;

  @media (min-width: 768px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

  return (
    <>
      <div className={`${style.section_heading} static`} id="testimonial">
        <h1>Testimonial</h1>
      </div>
      <div className={style.testimonial_container}>
        <div data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-once
          className={`${style.testimonial_content_wrapper} `}>
          <StyledCarousel
            autoPlay
            infiniteLoop
            interval={1500}
            showArrows={false}
            showStatus={false}
            dynamicHeight={false}
            showThumbs={false}
          >

            {/* Testimonial 1 */}
            <div className="flex justify-center mb-8">
              <div style={{ width: '100%' }}>
                <div class={`${style.testimonial_user_img} relative top-16`}>
                  <img style={{ width: '10vw', border: '1px solid' }} class="rounded-full border " src="/images/charulVakta.png" alt="" />
                </div>
                <div style={{ background: '#090921' }} class={`${style.testinomial_box_container} w-[100%] h-[18rem] justify-center gap-4 flex flex-col-reverse p-8 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}>
                  <div class="flex justify-end space-x-4 mb-4">
                    <div class={`${style.testimonial_name_content} text-justify font-medium text-white`}>
                      <div style={{ fontFamily: 'clashMed' }}>Charul Vakta</div>
                      <div style={{ fontFamily: 'clashMed' }} class="text-sm text-gray-400 dark:text-gray-400">Executive Director, YMCA Oasis Ahmedabad, Gujrat</div>
                    </div>
                  </div>
                  <p class="text-justify mb-3 font-normal text-gray-100 dark:text-gray-400">
                    The virtual walkthrough of YMCA created by Vradicals India Pvt Ltd is outstanding and incredibly realistic, surpassing the original. The attention to detail and quality of work are top-notch, making it an immersive experience in VR. Kudos to the Vradicals India team for their exceptional work. I highly recommend their services for a high-quality virtual experience.

                  </p>
                </div>
              </div>
            </div>

            {/* Testimonial 2 */}
            <div className="flex justify-center mb-8">
              <div className="w-[100%]">
                <div class={`${style.testimonial_user_img} relative top-16`}>
                  <img style={{ width: '10vw', height: '10vw', border: '1px solid', borderColor: '#090921' }} class="rounded-full border " src="/images/anantMehra.png" alt="" />
                </div>
                <div style={{ background: '#090921' }} class={`${style.testinomial_box_container} w-[100%] h-[18rem] justify-center gap-4 flex flex-col-reverse p-8 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}>
                  <div class="flex justify-end space-x-4 mb-4">
                    <div class={`${style.testimonial_name_content} text-justify font-medium text-white`}>
                      <div style={{ fontFamily: 'clashMed' }}>Anant Aditya Mehra</div>
                      <div style={{ fontFamily: 'clashMed' }} class="text-sm text-gray-400 dark:text-gray-400">Director,  ADITYA PUBLIC SCH GADARWARA, MP</div>
                    </div>
                  </div>
                  <p class="text-justify mb-3 font-normal text-gray-200 dark:text-gray-400">
                    I'm thrilled with the VR lab being implemented by VRadicals India at Aditya Public School. Even partially completed, it's already enhancing our educational environment with impressive realism and interactivity. The quality of their work is exceptional, and I'm excited to see the finished project. Kudos to VRadicals India for modernizing our resources!

                  </p>
                </div>
              </div>
            </div>

            {/* Testimonial 3 */}
            <div className="flex justify-center mb-8">
              <div className="w-[100%]">
                <div class={`${style.testimonial_user_img} relative top-16`}>
                  <img style={{ width: '10vw', border: '1px solid', borderColor: '#090921' }} class="rounded-full border " src="/images/nirmalBhatnagar.jpg" alt="" />
                </div>
                <div style={{ background: '#090921' }} class={`${style.testinomial_box_container} w-[100%] h-[18rem] justify-center gap-4 flex flex-col-reverse p-8 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}>
                  <div class="flex justify-end space-x-4 mb-4">

                    <div class={`${style.testimonial_name_content} text-justify font-medium text-white`}>
                      <div style={{ fontFamily: 'clashMed' }}>Nirmal Bhatnagar</div>
                      <div style={{ fontFamily: 'clashMed' }} class="text-sm text-gray-400 dark:text-gray-400">Director (Training) at Dreams Achievers</div>
                    </div>
                  </div>
                  <p class="text-justify mb-3 font-normal text-gray-200 dark:text-gray-400">Partnering with VRadicals India Pvt. Ltd. was a strategic move for our company. Their expertise in emerging technologies like Web 3.0 and Blockchain has given us a competitive edge. Their forward-thinking approach aligns perfectly with our vision for the future..</p>
                </div>
              </div>
            </div>

          </StyledCarousel>

        </div>
        <div className={style.BG_Shraed} />
      </div>
    </>
  );
};

export default TestimonialPage;
