import React, { useEffect } from "react";
import SectionNavbar from "../components/sheared/SectionNavbar";
import FooterPage from "../Footer/FooterPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import style from "../assets/styles/main.module.css";
// import { FaSchool } from "react-icons/fa";
// import { PiVirtualReality } from "react-icons/pi";
// import { FaKey } from "react-icons/fa";
export default function MoreMedia() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 0,
          cssEase: "linear",
          pauseOnHover: false,

        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <SectionNavbar />
      <div className="text-white" >
        <div className={style.section_heading}>
          <h1
            className={" text-3xl text-center p-3 mt-16 pt-20 mb-3 "}
          >
            <b>Our Press Release</b>
          </h1>
        </div>
        <div className="flex justify-between md:flex-row flex-col more-media gap-5 md:px-10 px-5">
          <div
            className="w-full md:w-[50vw] flex justify-center"
          // data-aos="fade-up"
          // data-aos-duration="1000"
          >
            <img src="/images/img7.png" alt="" className="w-[100%] h-[100%]" />
          </div>
          <div className="flex items-center flex-col black-blur md:w-[50%] w-full  ">
            <img
              src="/images/news.jpg"
              className="w-[100%] h-[100%] lg:h-[30rem] rounded-3xl"
              alt=""
            />
            <h1 className="md:text-2xl mt-10 w-[80%]">
              <strong>Experience of AI and Virtual Reality In Education</strong>
            </h1>
            <p className="w-[80%] mt-5">
              <span className=" text-purple-500 ">
                <b>Ramkumar Singh</b>
              </span>{" "}
              and{" "}
              <span className=" text-purple-500 ">
                <b>Aditi Sharma</b>
              </span>{" "}
              of Indore, who run an IT company in Dubai, say that education in
              the virtual world has now become the new demand of the future. Now
              not only games will be played through virtual reality, but in this
              interesting way It will also be possible to study. For this, we
              have prepared a program named{" "}
              <span className=" text-purple-500 ">
                <b> Kalpverse </b>
              </span>{" "}
              , which will not only help in studies but will also work in
              providing guidance for the future.
            </p>
          </div>
        </div>
        <div className="flex justify-between md:flex-row flex-col more-media mt-10 pt-10 gap-5 md:px-10 px-5">
          <div className="justify-center flex items-center flex-col black-blur md:w-[50vw] ">
            <img
              src="/images/news2.jpeg"
              className="w-[100%] h-[100%] lg:h-[30rem] rounded-3xl"
              alt=""
            />
            <h1 className="md:text-2xl  mt-10 w-[80%]">
              <strong>
                Now 'Era' will improve city traffic, drivers will be trained
                with AI and VR
              </strong>
            </h1>
            <p className="w-[80%] mt-5">
              <span className="text-purple-500 ">
                <b>Aditi Sharma</b>
              </span>{" "}
              told that we are making videos of the city's main roads MG Road,
              AB Road, BRTA, Jawahar Marg, Rajbada area and other places. During
              the training, you will experience driving in these areas virtually
              while sitting in the cabin. Videos have been prepared for
              different times of the day, which will make the training perfect.
              AI will also give information about making challan in case of
              mistake.
            </p>
          </div>

          <div className=" md:w-[50%] w-full flex justify-center">
            <img src="/images/img3.png" alt="" className="w-[100%] h-[100%]" />
          </div>
        </div>
        <div className="mt-5 flex flex-col items-center">
          <img src="/images/news4.jpg" alt="" className="w-full" />
          <p className="!px-5 black-blur !pt-5 md:w-[70%] w-full">Darshan-Aarti
            Devotees will be able to do darshan and aarti while sitting at <span className="text-purple-500">Ramlala's house in 12 Jyotirlingas and Ayodhya.</span>
            Youth of the city prepared VR project, preparing to install it at many places. <br />Not only in the country, people from all over the world are going to Ayodhya to have darshan of Ramlala, but at this stage of age when people cannot stand properly, it is very difficult for them to go there and have darshan. Never thought that I would be able to go to Ayodhya, but today I am sitting at home and having darshan of Ram temple and Ramlala.
            Life became successful.</p>
          <div className="flex justify-between lg:flex-row flex-col gap-5 mt-10 px-5">
           <div className="flex justify-between flex-col md:flex-row news-box2 ">
           <div className="flex justify-center md:contents">
            <img src="/images/news3.jpg" alt="" className="w-[300px] rounded-xl" />
           </div>
           
            <p className="black-blur !p-5  !py-5 "><h3 className="text-purple-500 text-center mb-3"><b>Sitting at home... Ramlala darshan in Ayodhya</b></h3>Sitting at home... Ramlala darshan in Ayodhya
              Shri Ram Darshan of elders and children. If it is difficult, then darshan can be done easily with just one device.
              Indore's startup company has prepared a Virtual Reality (VR) device. With its help, darshan of Ram temple of Ayodhya can be done virtually. Its trial took place yesterday evening in the temples of Vaishnodham and Sudama Nagar. Here, hundreds of citizens had darshan of Shri Ram on Ram Navami. Standing at one place, you can see the Ram temple 360 ​​degrees.</p>
           </div>
           <div className="flex justify-between flex-col md:flex-row news-box2 ">
           <p className="black-blur !p-5 !py-5 "><h3 className="text-purple-500 text-center mb-3"><b>Ramlala darshan in Khatushyam temple Dhamnod</b></h3>Had darshan of Ramlala in Shrireva Kuber Shani Khatushyam temple Dhamnod @ Patrika.
               Now Ayodhya Ramlal can be easily seen in the Reva Kuber Shani Khatu Shyam temple of the city. Indore's startup company has prepared a virtual reality device. With its help, darshan of Ram temple of Ayodhya is being done. Its formal beginning also took place on Friday, where devotees saw the entire Ramlal in a 4-minute video. Devotees told that it feels as if we are in Ayodhya.</p>
           <div className="flex justify-center md:contents">
             <img src="/images/news5.jpg" alt="" className="w-[300px] rounded-xl" />
           </div>
           </div>
          </div>
        </div>
        <div className={style.section_heading}>
          <h1
            className={" text-3xl text-center p-3 mt-10 pt-10 mb-3 "}
          >
            <b>Our Events</b>
          </h1>
        </div>
        <div className="flex justify-between md:flex-row flex-col gap-3 p-5">
          <div className="flex items-center flex-col black-blur md:w-[45%] w-full  ">
            <img src="/images/img2.png" className="w-[100%] h-[35rem]" alt="" />
            <h1 className="md:text-2xl  mt-10">
              <strong>Pratibha Samman Samaroh 2023</strong>
            </h1>
            <p className="w-[80%] mt-5">
              In a heartwarming initiative, the Pratibha Samman Samaroh 2023 was
              organized with a special focus on uplifting students from backward
              and underprivileged backgrounds who excelled in their 10th and
              12th grade examinations. Recognizing their remarkable
              achievements, our company proudly distributed laptops,
              smartphones, and cycles to these deserving scholars. This gesture
              not only rewards their hard work and dedication but also empowers
              them with tools to further their education and explore new
              horizons. Together, we're building a brighter future where every
              student has the opportunity to thrive and succeed.
            </p>
          </div>

          <div className="flex items-center flex-col black-blur md:w-[45%] w-full  ">
            <img src="/images/img4.png" className="w-[100%] h-[35rem]" alt="" />
            <h1 className="md:text-2xl  mt-10">
              <strong>Our First Official Meeting </strong>
            </h1>
            <p className="w-[80%] mt-5">
              In the atmosphere of{" "}
              <span className="text-purple-500 ">
                <b>Vradicals'</b>
              </span>{" "}
              first meeting, anticipation mingled with ambition. Unified by our
              mission to redefine reality through virtual realms, we delved into
              market analysis, product development, and collaborative research
              initiatives. Crafting compelling marketing strategies and
              committing to unwavering quality assurance, we left the meeting
              with a sense of purpose. With action items set and determination
              ablaze, we embarked on our journey.
            </p>
          </div>
          <div className="flex items-center flex-col black-blur md:w-[45%] w-full  ">
            <h1 className="md:text-2xl  mt-10">
              <strong>Course Design In Metaverse </strong>
            </h1>
            <p className="w-[80%] mt-5">
              Courses in Metaverse have been designed to give technology
              experience to students of classes 5 to 8 Class and make studies
              interesting. Courses have been made through NCERT. Students will
              be able to study through this application. Apart from this,
              preparations are also going on to open centers in schools. With
              this, students will be able to enjoy studies like games.
            </p>
            <h1 className="md:text-2xl  mt-10">
              <strong>Diffrent Syallabus Of School</strong>
            </h1>
            <p className="mt-5 w-[80%]">
              After going to 9th class, students have to search. The program
              will help in career guidance. Many other courses like Artificial
              Intelligence, Virtual Reality, Block Chain, Gaming and Metaverse
              Development have also been created.
            </p>
            <h1 className="md:text-2xl  mt-10">
              <strong>Training before Getting License</strong>
            </h1>
            <p className="mt-5 w-[80%]">
              <span className="text-purple-500 ">
                <b>Ram Kumar Singh</b>
              </span>{" "}
              said that training will be given through this project before
              getting the license to drive.{" "}
              <span className="text-purple-500">Virtual driving</span> will be
              done with information about traffic rules. All the information
              like where mistakes were made while driving, where rules were
              broken, carelessness etc. will be available from AI. A complete
              report card of the drive will be generated. How many challans were
              made, how much fine All information like will have to be paid Will
              get it. There will be training of 2-2 hours for seven days and
              then you will be able to get the license from RTO.
            </p>
          </div>
        </div>
        <div>
          <Slider {...settings}>
            <div>
              <img src="/images/img1.png" alt="" className="p-2" />
            </div>
            <div>
              <img src="/images/img2.png" alt="" className="p-2" />
            </div>
            <div>
              <img src="/images/img3.png" alt="" className="p-2" />
            </div>
            <div>
              <img src="/images/img4.png" alt="" className="p-2" />
            </div>
            <div>
              <img src="/images/img5.png" alt="" className="p-2" />
            </div>
            <div>
              <img src="/images/img6.png" alt="" className="p-2" />
            </div>
          </Slider>
        </div>
      </div>
      <FooterPage />
    </div>
  );
}
